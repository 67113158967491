import React, { FunctionComponent } from "react"
import Link from "../../components/Link"
import { Box, Themed } from "theme-ui"

interface OwnProps {}

type Props = OwnProps

const NotFound: FunctionComponent<Props> = () => {
  return (
    <Box as="main" sx={{ p: 4 }}>
      <Themed.h2>UNFORTUNATELY...</Themed.h2>
      <Themed.p>
        This is not the page your are looking for... You are on sample-cm.com
        but the address of this page may have change.
      </Themed.p>
      <Themed.p>
        Ce n'est pas la page que vous cherchez.. Vous êtes bien sur le site de
        sample-cm mais l'adresse de cette page peut avoir changé.
      </Themed.p>
      <Link to="/">Go back</Link>
    </Box>
  )
}

export default NotFound

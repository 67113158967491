import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import NotFound from "../components/NotFound"

type Props = PageProps & {
  data: {
    homePage: SCM.HomePage
  }
}

const NotFoundTemplate: FunctionComponent<Props> = props => {
  const homePage = props.data.homePage

  return (
    <Layout>
      <SEO
        locale={homePage.locale}
        uri={props.uri}
        {...homePage.seo}
        noindex={true}
        nofollow={true}
      />
      <NotFound />
    </Layout>
  )
}

export default NotFoundTemplate

export const query = graphql`
  query NotFoundQuery {
    homePage {
      ...homePageFragment
    }
  }
`
